<messages>["../Domain"]</messages>

<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-icon :color="color" v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    {{ $t(`view.label.applicationState.${statusNormalized}`) }}
  </v-tooltip>
</template>

<script>

  const STATUS = {
    NONE: 'none',
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected'
  }

  export default {
    props: {
      status: {
        type: String,
        required: true
      }
    },

    computed: {
      statusNormalized () {
        return this.status.toLowerCase ()
      },

      color () {
        switch (this.statusNormalized) {
          case STATUS.NONE:
            return 'grey'
          case STATUS.PENDING:
            return 'warning'
          case STATUS.ACCEPTED:
            return 'success'
          case STATUS.REJECTED:
            return 'error'
          default:
            return 'grey'
        }
      },

      icon () {
        switch (this.statusNormalized) {
          case STATUS.NONE:
            return 'help'
          case STATUS.PENDING:
            return 'access_alarm'
          case STATUS.ACCEPTED:
            return 'check_circle'
          case STATUS.REJECTED:
            return 'cancel'
          default:
            return 'help'
        }
      }
    }
  }
</script>
