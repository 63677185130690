<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '2' : '3'">
    <v-col cols="12" lg="8" xl="4">
      <!-- search filter -->
      <domain-filter
        :type="type"
        :value="filter || undefined"
        :visible="showFilter"
        :search-disabled="isLoading"
        @input="onFilterChange"
        @visibilityChanged="onFilterVisibilityChange"/>
    </v-col>

    <!-- domain list -->
    <v-col :class="tableClasses">
      <v-alert
        v-model="showResultAlert"
        v-t="'general.info.searchResults'"
        type="info"
        color="primary"/>
      <v-card v-if="isResultTableVisible">
        <v-card-title primary-title>
          <div
            v-t="`list.title.${type}`"
            class="text-h5"/>
          <v-spacer/>
          <csv-download-btn :total-count="totalCount" :csv-download-url="csvDownloadUrl"/>
        </v-card-title>
        <v-card-text>
          <domain-table
            :type="type"
            v-bind="paginationState"
            :domain-data="resultList"
            :total-count="totalCount"
            :hide-client="mayViewOnlyOwnObjects"
            :rows-per-page-items="rowsPerPageItems"
            :loading="isLoading"
            @delete="openConfirmDeleteDialog"
            @renew="openRenewDialog"
            @changePhase="openPhaseDialog"
            @restore="openRestoreDialog"
            @shift="openShiftDialog"
            @states="openStatesDialog"
            @requestAuth="openAuthRequest"
            @sync="syncDomains"
            @lock="lockDomain"
            @unlock="unlockDomain"
            @requestLock="requestLockDomain"
            @requestUnlock="requestUnlockDomain"
            @conclude="concludeApplication"
            @paginationStateChanged="({newValue, oldValue}) =>
              onPaginationStateChanged (newValue, oldValue)"/>
        </v-card-text>
        <registry-object-delete-dialog
          v-if="deleteObject"
          v-model="isDeleteDialogVisible"
          :delete-object="deleteObject"
          @deleted="load"/>

        <template v-if="domainObject">
          <renew-dialog
            v-model="renewDialog"
            :renew-object="domainObject"
            @renewed="load"/>
          <change-phase-dialog
            v-model="phaseDialog"
            :phase-object="domainObject"
            @changed="load"/>
          <restore-dialog
            v-model="restoreDialog"
            :restore-object="domainObject"
            @restored="load"/>
          <shift-dialog
            v-model="shiftDialog"
            :shift-object="domainObject"
            @shifted="load"/>
          <states-dialog
            v-if="statesDialog"
            v-model="statesDialog"
            type="domain"
            :object="domainObject"
            @success="load"/>
          <auth-info-dialog
            ref="authInfoDialog"
            v-model="authInfoDialog"
            :object="domainObject"/>
          <lock-request-dialog
            v-model="lockDialog"
            :domain-data="domainObject"
            :perform-lock="performLock"
            :unlock="unlock"
            @success="load"/>

          <conclude-application-dialog
            v-model="concludeDialog"
            :domain-data="domainObject"
            @success="load"/>
        </template>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

  import SearchPage from '@/app/core/mixins/SearchPage'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import DomainFilter from '@/app/pages/Domain/components/DomainFilter'
  import DomainTable from './DomainTable'

  import RegistryObjectDeleteDialog
    from '@/app/core/components/RegistryObject/RegistryObjectDeleteDialog'

  import RenewDialog
    from '@/app/core/components/RegistryObject/domain/RenewDialog'

  import RestoreDialog
    from '@/app/core/components/RegistryObject/domain/RestoreDialog'

  import ShiftDialog
    from '@/app/core/components/RegistryObject/domain/ShiftDialog'

  import StatesDialog
    from '@/app/core/components/RegistryObject/StatesDialog'

  import AuthInfoDialog
    from './components/DomainAuthInfoDialog'

  import ChangePhaseDialog from './components/ChangePhaseDialog'

  import ConcludeApplicationDialog
    from './components/ConcludeApplicationDialog.vue'

  import LockRequestDialog from './components/LockRequestDialog'

  import {TYPES, typeProp} from './ApplicationSupport'
  import CsvDownloadBtn from '@/app/core/components/CsvDownloadBtn'

  export default {
    name: 'DomainSearch',

    components: {
      CsvDownloadBtn,
      BaseLayout,
      DomainFilter,
      DomainTable,
      RegistryObjectDeleteDialog,
      RenewDialog,
      RestoreDialog,
      ShiftDialog,
      StatesDialog,
      AuthInfoDialog,
      LockRequestDialog,
      ChangePhaseDialog,
      ConcludeApplicationDialog
    },

    mixins: [SearchPage],

    props: {
      type: typeProp
    },

    data () {
      return {
        DELETE_OBJECT_STATIC_PROPS: {
          url: `${this.type}/delete`,
          objectType: this.type
        },
        domainObject: null,
        renewDialog: false,
        phaseDialog: false,
        restoreDialog: false,
        shiftDialog: false,
        statesDialog: false,
        authInfoDialog: false,
        lockDialog: false,
        concludeDialog: false,
        unlock: false,
        performLock: false,
        isLoadingAuth: false,
        authData: null
      }
    },

    computed: {
      ...mapState ('filter', [
        'domainSearchQuery',
        'applicationSearchQuery'
      ]),
      ...mapGetters ({
        mayViewOnlyOwnObjects: 'auth/mayViewOnlyOwnObjects'
      }),

      storedSearchQuery () {
        return this.type === TYPES.APPLICATION
          ? this.applicationSearchQuery
          : this.domainSearchQuery
      },

      defaultSortBy () {
        return 'name'
      },

      /**
       * specify list operation
       *
       * @return {string}
       */
      OPERATION () {
        // this value is used by mixin for sending requests to the BE API
        return `${this.type}/list`
      },

      tableClasses () {
        return this.mayViewOnlyOwnObjects
          ? ['col-8', 'col-xl-6']
          : ['col-12', 'col-xl-8']
      }
    },

    watch: {
      type () {
        this.updateProps ({
          filter: null,
          pagination: {},
          ...this.getStoredSearchQuery ()
        })
      }
    },

    methods: {
      // --- mutations and actions, mapped from vuex ---------------------------
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        setDomainSearchQuery: 'filter/setDomainSearchQuery',
        setApplicationSearchQuery: 'filter/setApplicationSearchQuery'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * store the search filter to the Vuex
       */
      storeSearchQuery (query) {
        if (this.type === TYPES.APPLICATION) {
          this.setApplicationSearchQuery (query)
        } else {
          this.setDomainSearchQuery (query)
        }
      },

      /**
       * make a request for a new auth info for the specified domain
       */
      openAuthRequest (data) {
        this.authInfoDialog = true
        this.domainObject = {
          ...data
        }
        this.$nextTick (() => {
          this.$refs.authInfoDialog.requestAuth ()
        })
      },

      /**
       * implementation of getting the stored search filter, which will be used
       * on component creation
       */
      getStoredSearchQuery () {
        return this.storedSearchQuery
      },

      // --- further helper methods --------------------------------------------

      /**
       * Synchronize domains with the registry
       *
       * @param {Array} names   names of domains to be synchronized
       */
      syncDomains (names) {
        this.fetchData ({
          op: 'domain/sync',
          params: {
            names
          },
          cb: data => {
            const count = Object.keys (data.names).length
            this.displaySuccessMessage (
              this.$tc ('sync.success'),
              count, {
                count
              })

            this.load ()
          }
        })
      },

      openRenewDialog (e) {
        this.domainObject = {
          ...e
        }
        this.renewDialog = true
      },

      openPhaseDialog (e) {
        this.domainObject = {
          ...e
        }
        this.phaseDialog = true
      },

      lockDomain (e) {
        this.domainObject = {
          ...e
        }
        this.performLock = true
        this.unlock = false
        this.lockDialog = true
      },

      unlockDomain (e) {
        this.domainObject = {
          ...e
        }
        this.performLock = true
        this.unlock = true
        this.lockDialog = true
      },

      requestLockDomain (e) {
        this.domainObject = {
          ...e
        }
        this.performLock = false
        this.unlock = false
        this.lockDialog = true
      },

      concludeApplication (e) {
        this.domainObject = {
          ...e
        }
        this.concludeDialog = true
      },

      requestUnlockDomain (e) {
        this.domainObject = {
          ...e
        }
        this.performLock = false
        this.unlock = true
        this.lockDialog = true
      },

      openRestoreDialog (e) {
        this.domainObject = {
          ...e
        }
        this.restoreDialog = true
      },

      openShiftDialog (e) {
        this.domainObject = {
          ...e
        }
        this.shiftDialog = true
      },

      openStatesDialog (e) {
        this.domainObject = {
          ...e
        }
        this.statesDialog = true
      }
    }
  }
</script>
