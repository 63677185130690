<messages>["./ShiftDialog"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t (`title`)"
    :error-msg="errorMsg"
    :is-loading="isLoading"
    @ok="request">
    <v-row>
      <v-col cols="12">
        <p v-text="$t ('intro', {domain: shiftObject.name})"/>
        <client-select
          v-model="internalClientId"
          @loading="onClientsLoading"
          @loaded="onClientsLoaded"/>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="includeReferenced"
          class="mt-6"
          :items="inculdeOptions"
          :label="$t ('include-referenced.label')"
          hide-details/>
      </v-col>
      <v-col v-if="response" cols="12">
        <v-btn text color="error" @click="showDetails = !showDetails">
          {{ $t(`general.label.${showDetails ? 'hideErrorDetails' : 'showErrorDetails'}`) }}
        </v-btn>
      </v-col>
      <v-col v-if="response && showDetails" cols="12">
        <order-processing-response :response="response" :show-card-border="false" hide-title/>
      </v-col>
    </v-row>
  </confirmation-dialog>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import ClientSelect from '@/app/core/components/ClientSelect'

  import OrderProcessingResponse
    from '@/app/core/components/RegistryObject/OrderProcessingResponse'

  export default {
    name: 'ShiftDialog',

    components: {
      ConfirmationDialog,
      ClientSelect,
      OrderProcessingResponse
    },

    props: {
      shiftObject: {
        type: Object,
        required: true,
        validator (value) {
          return ['v_id', 'name'].every ((key) => {
            return value[key] !== undefined
          })
        }
      },
      value: {
        type: Boolean,
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: '',
        internalClientId: 0,
        includeReferenced: null,
        availableClients: [],
        loadingClients: false,
        response: null,
        showDetails: false
      }
    },

    computed: {
      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      inculdeOptions () {
        return [
          {
            text: this.$t ('include-referenced.none'),
            value: null
          },
          {
            text: this.$t ('include-referenced.all'),
            value: false
          },
          {
            text: this.$t ('include-referenced.owned'),
            value: true
          }
        ]
      }
    },

    watch: {
      clientId (newValue) {
        this.internalClientId = newValue || this.preSelectOwnClient ()
      },
      value (newValue) {
        if (!newValue) {
          this.errorMsg = ''
        }
      },
      showDialog (newValue) {
        if (!newValue) {
          this.response = null
          this.errorMsg = ''
          this.internalClientId = 0
          this.includeReferenced = null
          this.showDetails = false
        }
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      request () {
        this.isLoading = true
        this.response = null

        this.fetchData ({
          op: 'domain/shift',
          params: {
            id: this.shiftObject.v_id,
            clientId: this.internalClientId,
            includeReferenced: this.includeReferenced
          },
          cb: data => {
            this.isLoading = false
            this.displaySuccessMessage (this.$t ('success',
                                                 {client: this.internalClientId}))

            this.addEvent ({
              type: 'success',
              message: this.$t ('success',
                                {client: this.internalClientId}),
              objects: [{name: this.shiftObject.name, link: {name: 'domain.view.version', params: {vid: this.shiftObject.v_id}}}]
            })

            this.$emit ('shifted')
            this.errorMsg = ''
            this.showDialog = false
          },
          cbError: data => {
            if (data.errors ? data.errors.length > 0 : false) {
              this.errorMsg = data.errors[0].message

              this.response = data.errors
            } else {
              this.errorMsg = this.$t ('error')
            }
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      /**
       * Show progress bar when clients are being loaded.
       */
      onClientsLoading () {
        this.loadingClients = true
      },

      /**
       * Stop showing progress bar when clients have been loaded
       */
      onClientsLoaded (clients) {
        this.loadingClients = false
        this.availableClients = clients
      }
    }
  }
</script>
