<messages>["./RestoreDialog"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t (`title`)"
    :error-msg="errorMsg"
    :is-loading="isLoading"
    @ok="request">
    <v-row>
      <v-col cols="12">
        <p v-text="$t ('intro', {domain: restoreObject.name})"/>
      </v-col>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model.trim="price"
            :label="$t('price')"
            :error-messages="validationErrors(
              'price',
              {
                decimal: 'invalid.price'
              })"
            @blur="$v.price.$touch()">
            <template #append>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    color="primary"
                    dark
                    v-on="on">
                    help
                  </v-icon>
                </template>
                <span v-t="'priceTooltip'"/>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col
          class="ml-2"
          cols="6">
          <currency-select :v="$v.currency"/>
        </v-col>
      </v-row>
    </v-row>
  </confirmation-dialog>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import {requiredIf} from 'vuelidate/lib/validators'

  import {decimal} from '@/app/validators'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  import CurrencySelect from '@/app/core/components/CurrencySelect'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {
    name: 'RestoreDialog',

    components: {
      ConfirmationDialog,
      CurrencySelect
    },

    validations: {
      currency: {
        required: requiredIf (function () {
          return this.price
        })
      },
      price: {
        decimal
      }
    },

    mixins: [validationMixins],

    props: {
      restoreObject: {
        type: Object,
        required: true,
        validator (value) {
          return ['v_id', 'name'].every ((key) => {
            return value[key] !== undefined
          })
        }
      },
      value: {
        type: Boolean,
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: '',
        price: '',
        currency: ''
      }
    },

    computed: {
      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      }
    },

    watch: {
      showDialog (newVal) {
        if (!newVal) {
          this.errorMsg = ''
          this.price = ''
          this.currency = ''
        }
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      request () {
        if (this.$v.$invalid) {
          this.$v.$touch ()
        } else {
          this.isLoading = true

          this.fetchData ({
            op: 'domain/restore',
            params: {
              id: this.restoreObject.v_id,
              priceLimit: {
                value: this.price !== '' ? this.price : null,
                currency: this.price !== '' ? this.currency : null
              }
            },
            cb: data => {
              this.isLoading = false
              this.displaySuccessMessage (data.accepted
                ? this.$t ('accepted')
                : this.$t ('success'))

              this.addEvent ({
                type: 'success',
                message: data.accepted
                  ? this.$t ('accepted')
                  : this.$t ('success'),
                objects: [{name: this.restoreObject.name, link: {name: 'domain.view.version', params: {vid: this.restoreObject.v_id}}}]
              })

              this.$emit ('restored')
              this.showDialog = false
            },
            cbError: data => {
              console.log (data)
              if (data.errors ? data.errors.length > 0 : false) {
                this.errorMsg = data.errors[0].message
              } else {
                this.errorMsg = this.$t ('error')
              }
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        }
      }
    }
  }
</script>
