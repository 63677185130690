<template>
  <v-select
    v-model="selectedPhase"
    :label="label"
    :item-text="(i) => i.name + (i.subname ? '/' + i.subname : '' )"
    :item-value="(i) => i.isNull ? null : ({name: i.name, subname: i.subname})"
    :items="selectablePhases"/>
</template>

<script>

  export default {
    name: 'LaunchPhaseInput',

    props: {
      value: {
        type: Object,
        default: undefined
      },
      availablePhases: {
        type: Array,
        default: () => ([])
      },
      nullSelectable: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      }
    },

    computed: {
      selectedPhase: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      selectablePhases () {
        return [
          ...this.nullSelectable ? [{name: this.$t ('general.label.none'), isNull: true}] : [],
          ...this.availablePhases
        ]
      }
    }
  }
</script>
