<messages>["../Domain"]</messages>

<template>
  <confirmation-dialog
    v-model="dialogState"
    :custom-btn-text="$t(`view.label.conclude.btn`)"
    :headline="$t(`view.label.conclude.title`, {name: domainData.name})"
    :is-loading="isLoading"
    @ok="submit">
    <template #default>
      <v-select
        v-model="successApplication"
        :items="successOptions"
        :label="$t(`view.label.conclude.successApplication`)"
        :error-messages=" validationErrors (
          'successApplication', {
            required: 'general.required',
          })"
        @blur="$v.successApplication.$touch"/>
      <v-expand-transition mode="out-in">
        <v-checkbox v-if="successApplication" v-model="rejectRemaining" :label="$t(`view.label.conclude.rejectRemaining`)"/>
      </v-expand-transition>
      <v-expand-transition mode="out-in">
        <v-text-field v-if="successApplication != null && !successApplication || (!!successApplication && rejectRemaining)" v-model="reason" :label="$t(`view.label.conclude.reason.${successApplication ? 'remaining' : 'this'}`)"/>
      </v-expand-transition>
    </template>

    <template v-if="errorMsg || transactionId" #error>
      <p>{{ errorMsg }}</p>
      <p>{{ $t('view.label.conclude.transactionId') }}: {{ transactionId }}</p>
    </template>
  </confirmation-dialog>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import {required} from 'vuelidate/lib/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  export default {
    components: {
      ConfirmationDialog
    },

    mixins: [validationMixins],

    validations: {
      successApplication: {
        required
      }
    },

    props: {
      domainData: {
        type: Object,
        required: true,
        validator: function (value) {
          // The value must have a name and an id
          return ['id', 'name'].every ((key) => {
            return value[key] !== undefined
          })
        }
      },
      value: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: null,
        successApplication: null,
        rejectRemaining: true,
        reason: null,
        transactionId: null
      }
    },

    computed: {
      dialogState: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      successOptions () {
        return [
          {value: true, text: this.$t ('general.yes')},
          {value: false, text: this.$t ('general.no')}
        ]
      }
    },

    watch: {
      dialogState (newVal) {
        if (!newVal) {
          this.errorMsg = null
          this.successApplication = null
          this.rejectRemaining = true
          this.transactionId = null
          this.reason = null
          this.$v.$reset ()
        }
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      submit () {
        if (this.$v.$invalid) {
          this.$v.$touch ()
          return
        }

        const op = 'application/conclude'
        const params = {
          id: this.domainData.id,
          success: this.successApplication
        }

        if (!this.successApplication ||
          (this.successApplication && this.rejectRemaining)) {
          params.reason = this.reason
        }

        if (this.successApplication) {
          params.rejectRemaining = this.rejectRemaining
        }

        this.errorMsg = null
        this.transactionId = null
        this.isLoading = true

        this.fetchData ({
          op,
          params,
          cb: data => {
            this.displaySuccessMessage (this.$t ('view.label.conclude.success'))
            this.dialogState = false
            this.$emit ('success')
          },
          cbError: (data, next) => {
            if (data?.result[0]?.code.indexOf ('error/server') === 0) {
              this.errorMsg = data.errors[0].message
            } else {
              this.errorMsg = this.$t ('view.label.conclude.generalError')
              next (data)
            }

            this.transactionId = this.getTransactionId (data?.result)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      getTransactionId (result) {
        return result?.find ((r) => r.code === 'error/transactionId')?.params?.value
      }
    }
  }
</script>
