<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="domainData"
    :page.sync="pagination.page"
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    :footer-props="{...footerProps, 'items-per-page-options': rowsPerPageItems}"
    :server-items-length="totalCount"
    :loading="loading">
    <template #item="props">
      <tr>
        <td>
          <router-link
            :to="detailsLink (props.item)"
            :class="{deletedItemLink: props.item.deleted || props.item.deferredDeletionHold || props.item.deletionHold}"
            v-text="props.item.name"/>
          <v-tooltip
            v-if="props.item.deferredDeletionHold || props.item.deletionHold"
            top>
            <template #activator="{ on }">
              <v-icon v-on="on">
                timer
              </v-icon>
            </template>
            <span v-if="props.item.pendingDeletionRefDate">
              {{ $t('list.state.pendingDeletion.tooltip.ref',
                    {date: formatDateShort(props.item.pendingDeletionRefDate, true)}) }}
            </span>
            <span v-else v-t="'list.state.pendingDeletion.tooltip.simple'"/>
          </v-tooltip>
        </td>
        <td v-if="!compactTable">
          <div class="breakall">
            {{ props.item.punycodeName }}
          </div>
        </td>
        <td v-if="!hideClient && !veryCompactTable">
          <client-link :id="props.item.clientId"/>
        </td>
        <td v-if="!compactTable">
          {{ formatLauchPhase(props.item.launchPhase) || EmptyMark }}
        </td>
        <td v-if="!veryCompactTable">
          {{ formatDateShort (props.item.creationDate, true) }}
        </td>
        <td v-if="!veryCompactTable && !isApplication">
          {{ formatDateShort (props.item.expirationDate, true) }}
        </td>
        <td v-if="!veryCompactTable && isApplication">
          <application-status-indicator :status="props.item.status"/>
        </td>
        <td>
          <action-buttons
            :value="isActionButtonsActive (props.item.versionId)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.versionId) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty.' + type)"/>
    </template>
  </v-data-table>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters, mapMutations} from 'vuex'

  import {EmptyMark} from '@/app/utils/string'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import registryCommands from '@/app/core/mixins/RegistryCommands'

  import ActionButtons from '@/app/core/components/ActionButtons'
  import ClientLink from '@/app/core/components/ClientLink'
  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'

  import {TYPES, typeProp} from './ApplicationSupport'

  import {isDeleteProhibited, isRenewProhibited, isUpdateProhibited}
    from '@/app/core/components/RegistryObject/StatesDialog'

  import ApplicationStatusIndicator from './components/ApplicationStatusIndicator'

  import {
    LOCKED_STATE,
    LOCK_REQUESTED_STATE,
    UNLOCK_REQUESTED_STATE,
    UNLOCKED_STATE,
    formatLauchPhase
  } from './constants'

  export default {
    name: 'DomainTable',

    components: {
      ActionButtons,
      ClientLink,
      SearchTableNoData,
      ApplicationStatusIndicator
    },

    mixins: [paginationMixins, actionButtonsHelper, registryCommands],

    props: {
      type: typeProp,
      domainData: {type: Array, default: () => []},
      totalCount: {type: Number, default: 0},
      rowsPerPageItems: {
        type: Array,
        default: undefined
      },
      loading: Boolean,
      hideClient: Boolean
    },
    data () {
      return {
        EmptyMark
      }
    },

    computed: {
      ...mapGetters ({
        mayManageObject: 'auth/mayManageObject',
        mayManageForeignObjects: 'auth/mayManageForeignObjects',
        permissions: 'auth/permissions',
        hasSubClients: 'auth/hasSubClients'
      }),

      isApplication () {
        return this.type === TYPES.APPLICATION
      },

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      },

      compactTable () {
        return this.$vuetify.breakpoint.smAndDown
      },

      veryCompactTable () {
        return this.$vuetify.breakpoint.xs
      },

      headers () {
        return [
          {
            text: this.$t ('list.header.name'),
            value: 'name'
          },
          ...this.compactTable
            ? []
            : [{
              text: this.$t ('list.header.punycodeName'),
              value: 'namePunycode'
            }],
          ...(this.hideClient || this.veryCompactTable)
            ? []
            : [{
              text: this.$t ('list.header.client'),
              value: 'clientId'
            }],
          ...this.compactTable
            ? []
            : [{
              text: this.$t ('list.header.phase'),
              value: 'launchPhase',
              sortable: false
            }],
          ...this.veryCompactTable
            ? []
            : [{
              text: this.$t ('list.header.creationDate'),
              value: 'created'
            }],
          ...this.veryCompactTable || this.isApplication
            ? []
            : [{
              text: this.$t ('list.header.expirationDate'),
              value: 'expires'
            }],
          ...this.veryCompactTable || !this.isApplication
            ? []
            : [{
              text: this.$t ('list.header.status'),
              value: 'status'
            }],
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      }
    },

    methods: {
      ...mapMutations ({
        setClonedDomainDataFor: 'create/setClonedDomainDataFor'
      }),

      formatLauchPhase,
      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        const isDomain = !this.isApplication
        const locked = item.lockStatus === LOCKED_STATE
        const unlocked = item.lockStatus === UNLOCKED_STATE
        const lockRequested = item.lockStatus === LOCK_REQUESTED_STATE
        const unlockRequested = item.lockStatus === UNLOCK_REQUESTED_STATE

        const sync = [...this.isAdmin && isDomain &&
          !this.isVirtual (item.registryType)
          ? [{
            action: 'sync',
            domainName: item.name,
            disabled: !this.isAdmin,
            icon: 'sync',
            tooltip: this.$t ('general.button.sync')
          }]
          : []]

        const concludeApplication = [...this.isAdmin && !isDomain &&
          this.isVirtual (item.registryType)
          ? [{
            action: 'conclude',
            domainName: item.name,
            disabled: item.status !== 'Pending',
            id: item.id,
            icon: 'check_circle',
            tooltip: this.$t ('view.label.conclude.btn')
          }]
          : []]

        const renew = [...isDomain
          ? [{
            action: 'renew',
            domainName: item.name,
            domainVersionId: item.versionId,
            registryId: item.registryType,
            disabled: !this.supports (item.registryType, 'DomainRenew') ||
              (item.deleted || item.deferredDeletionHold) || !(this.mayManageObject (item.clientId)) ||
              isRenewProhibited (item.states),
            icon: 'restore',
            // better icon: restore_from_trash, currently not working
            tooltip: this.$t ('general.button.renew')
          }]
          : []]

        const changePhase = [...isDomain && this.isAdmin
          ? [{
            action: 'changePhase',
            domainName: item.name,
            domainLaunchPhase: item.launchPhase,
            domainVersionId: item.versionId,
            domainId: item.id,
            registryId: item.registryType,
            icon: 'call_split',
            tooltip: this.$t ('general.button.phaseChange')
          }]
          : []]

        const restore = [...isDomain
          ? [{
            action: 'restore',
            domainName: item.name,
            domainVersionId: item.versionId,
            disabled: !this.supports (item.registryType, 'DomainRestore') ||
              (!item.deleted && !item.deferredDeletionHold) ||
              !(this.mayManageObject (item.clientId)),
            icon: 'delete_forever',
            tooltip: this.$t ('general.button.restore')
          }]
          : []]

        const requestAuth = [...isDomain
          ? [{
            action: 'requestAuth',
            domainName: item.name,
            domainVersionId: item.versionId,
            disabled: item.deleted || item.deferredDeletionHold ||
              item.deletionHold || !(this.mayManageObject (item.clientId)),
            icon: 'vpn_key',
            // better icon: restore_from_trash, currently not working
            tooltip: this.$t ('general.button.requestAuth')
          }]
          : []]

        const states = [...isDomain && this.supports (item.registryType, 'DomainStatusModify')
          ? [{
            ...item,
            action: 'states',
            disabled:
              !this.supports (item.registryType, 'DomainStatusModify') ||
              item.deleted || item.deferredDeletionHold || item.deletionHold ||
              !(this.mayManageObject (item.clientId)) || locked,
            icon: 'settings',
            tooltip: this.$t ('general.button.manageStates')
          }]
          : []]

        const lock =
          [...isDomain && this.supports (item.registryType, 'DomainLockModify') &&
            (!item.deleted && !item.deferredDeletionHold && !item.deletionHold)
            ? [{
              ...item,
              action: locked ? 'requestUnlock' : 'requestLock',
              domainName: item.name,
              domainVersionId: item.id,
              disabled: unlockRequested || lockRequested,
              icon: locked || lockRequested ? 'lock_open' : 'lock',
              tooltip: locked || unlockRequested
                ? unlockRequested
                  ? this.$t ('view.label.locking.unlockRequested')
                  : this.$t ('view.label.locking.requestunlock')
                : lockRequested
                  ? this.$t ('view.label.locking.lockRequested')
                  : this.$t ('view.label.locking.requestlock')
            }]
            : []]

        const lockAdmin = [...isDomain &&
          this.supports (item.registryType, 'DomainLockModify') &&
          this.isAdmin && (!item.deleted && !item.deferredDeletionHold && !item.deletionHold)
          ? [{
            ...item,
            action: lockRequested ? 'lock' : 'unlock',
            domainName: item.name,
            disabled: locked || unlocked,
            color: 'primary',
            domainVersionId: item.id,
            icon: locked || lockRequested ? 'lock' : 'lock_open',
            tooltip: unlocked || lockRequested
              ? lockRequested
                ? this.$t ('view.label.locking.confirmLock')
                : this.$t ('view.label.locking.status.unlocked')
              : unlockRequested
                ? this.$t ('view.label.locking.confirmUnlock')
                : this.$t ('view.label.locking.status.locked')
          }]
          : []]

        const view = [{
          ...item,
          action: 'view',
          icon: 'visibility',
          tooltip: this.$t ('general.button.view')
        }]

        const edit = [{
          ...item,
          action: 'edit',
          disabled: !this.supports (item.registryType, 'DomainModify') ||
            item.deleted || item.deferredDeletionHold || item.deletionHold ||
            !(this.mayManageObject (item.clientId)) ||
            (this.type === TYPES.APPLICATION && item.status !== 'Pending') ||
            isUpdateProhibited (item.states) || locked,
          icon: 'edit',
          tooltip: this.$t ('general.button.edit')
        }]

        const deleteAction = [{
          action: 'delete',
          actionArg: [item],
          icon: 'delete',
          tooltip: this.$t ('general.button.delete'),
          domainName: item.name,
          domainVersionId: item.versionId,
          disabled: !this.supports (item.registryType, 'DomainDelete') ||
            item.deleted || item.deferredDeletionHold ||
            !(this.mayManageObject (item.clientId)) ||
            isDeleteProhibited (item.states),
          color: 'error'
        }]

        const clone = [{
          ...item,
          action: 'clone',
          disabled: !this.supports (item.registryType, 'DomainCreate') ||
            item.deleted || item.deferredDeletionHold || item.deletionHold,
          icon: 'content_copy',
          tooltip: this.$t ('general.button.clone')
        }]

        const shift = [...isDomain && this.mayManageForeignObjects && this.hasSubClients
          ? [{
            action: 'shift',
            actionArg: [item],
            icon: 'swap_vert',
            tooltip: this.$t ('general.button.shift'),
            domainName: item.name,
            domainVersionId: item.versionId,
            disabled: item.deleted || item.deferredDeletionHold ||
              item.deletionHold || !(this.mayManageObject (item.clientId))
          }]
          : []
        ]

        return [
          ...view,
          ...concludeApplication, // application only
          ...states, // domain only
          ...edit,
          ...clone,
          ...isDomain && this.supports (item.registryType, 'DomainLockModify') &&
            (!item.deleted && !item.deferredDeletionHold && !item.deletionHold)
            ? [{divider: true}]
            : [],
          ...lock, // domain only
          ...lockAdmin, // domain only
          {divider: true},
          ...changePhase,
          ...requestAuth, // domain only
          ...renew, // domain only
          ...restore, // domain only
          ...deleteAction,
          ...isDomain ? [{divider: true}] : [],
          ...shift, // domain only
          ...sync // admin only, domain only
        ]
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        switch (button.action) {
          case 'view':
            this.$router.push (this.detailsLink (button))
            break
          case 'edit':
            this.$router.push ({
              name: this.type === TYPES.APPLICATION
                ? 'application.edit.id'
                : 'domain.edit',
              params: this.type === TYPES.APPLICATION
                ? {id: button.id}
                : {name: button.name}
            })
            break
          case 'states':
            this.$emit (
              'states',
              {
                v_id: button.versionId,
                id: button.id,
                name: button.name
              }
            )
            break
          case 'clone':
            this.setClonedDomainDataFor (null)
            this.$router.push ({
              name: this.type === TYPES.APPLICATION
                ? 'application.clone.id'
                : 'domain.clone',
              params: this.type === TYPES.APPLICATION
                ? {id: button.id}
                : {name: button.name}
            })
            break
          case 'sync':
            this.$emit ('sync', [button.domainName])
            break
          case 'renew':
            this.$emit (
              'renew',
              {
                v_id: button.domainVersionId,
                name: button.domainName,
                registryId: button.registryId
              }
            )
            break
          case 'restore':
            this.$emit (
              'restore',
              {
                v_id: button.domainVersionId,
                name: button.domainName
              }
            )
            break

          case 'changePhase':
            this.$emit (
              'changePhase',
              {
                v_id: button.domainVersionId,
                id: button.domainId,
                name: button.domainName,
                launchPhase: button.domainLaunchPhase,
                registryId: button.registryId
              }
            )
            break
          case 'delete':
            this.$emit (
              'delete',
              {
                v_id: button.domainVersionId,
                name: button.domainName
              }
            )
            break
          case 'shift':
            this.$emit (
              'shift',
              {
                v_id: button.domainVersionId,
                name: button.domainName
              }
            )
            break
          case 'requestAuth':
            this.$emit (
              'requestAuth',
              {
                v_id: button.domainVersionId,
                name: button.domainName
              }
            )
            break
          case 'lock':
            this.$emit (
              'lock',
              {
                v_id: button.domainVersionId,
                name: button.domainName
              }
            )
            break
          case 'unlock':
            this.$emit (
              'unlock',
              {
                v_id: button.domainVersionId,
                name: button.domainName
              }
            )
            break
          case 'requestLock':
            this.$emit (
              'requestLock',
              {
                v_id: button.domainVersionId,
                name: button.domainName
              }
            )
            break
          case 'requestUnlock':
            this.$emit (
              'requestUnlock',
              {
                v_id: button.domainVersionId,
                name: button.domainName
              }
            )
            break
          case 'conclude':
            this.$emit (
              'conclude',
              {
                id: button.id,
                name: button.domainName
              }
            )
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      },

      /**
       * handle pagination state changes
       *
       * @param newValue      new pagination state
       * @param oldValue      old pagination state
       */
      onPaginationStateChanged (newValue, oldValue) {
        this.$emit ('paginationStateChanged', {newValue, oldValue})
      },

      formattedDate (value) {
        return !value ? '' : this.formatDateShort (value)
      },

      /**
       * Generate the link data for the domain details view.
       *
       * @param {Object} domainData     the domain to create the link for
       */
      detailsLink (domainData) {
        let res

        switch (this.type) {
          case TYPES.DOMAIN:
            res = domainData.deleted
              ? {
                name: 'domain.view.version',
                params: {vid: domainData.versionId}
              }
              : {
                name: 'domain.view',
                params: {name: domainData.name}
              }
            break

          case TYPES.APPLICATION:
            res = {
              name: 'application.view.id',
              params: {id: domainData.id}
            }
            break

          default:
            console.error (`Object type "${this.type}" is unknown`)
        }

        return res
      }
    }
  }
</script>
