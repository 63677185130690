<messages>["../Domain"]</messages>

<template>
  <confirmation-dialog
    v-model="dialogState"
    :custom-btn-text="$t(`view.label.locking.${unlock ? 'unlock' : 'lock'}`)"
    :headline="title"
    :is-loading="isLoading"
    :error-msg="errorMsg"
    @ok="submit">
    <p>{{ text }}</p>
  </confirmation-dialog>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  export default {
    components: {
      ConfirmationDialog
    },

    props: {
      domainData: {
        type: Object,
        required: true,
        validator: function (value) {
          // The value must have a name and a id
          return ['v_id', 'name'].every ((key) => {
            return value[key] !== undefined
          })
        }
      },
      value: {
        type: Boolean,
        default: false
      },
      unlock: {
        type: Boolean,
        default: false
      },
      performLock: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: null
      }
    },

    computed: {
      dialogState: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      title () {
        return this.$t (
          `view.label.locking.${this.performLock ? 't' : 'requestT'}itle.${this.unlock ? 'unlock' : 'lock'}`,
          {name: this.domainData.name}
        )
      },

      text () {
        const prefix = (i) => this.$t (`view.label.locking.text.${i}`)
        const action = this.unlock ? 'unlock' : 'lock'
        if (this.performLock) return prefix (action)
        return prefix (`request${action}`)
      },

      action () {
        const action = this.unlock ? 'unlock' : 'lock'
        if (this.performLock) return action
        return `${action}Requested`
      }
    },

    watch: {
      dialogState (newVal) {
        if (!newVal) {
          this.errorMsg = null
        }
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      submit () {
        const op = `domain/${this.performLock ? 'lock' : 'request-lock'}`
        const params = {
          id: this.domainData.v_id,
          lock: !this.unlock
        }
        this.errorMsg = null
        this.isLoading = true

        this.fetchData ({
          op,
          params,
          cb: data => {
            this.displaySuccessMessage (this.$t (`view.label.locking.success.${this.action}`))
            this.dialogState = false
            this.$emit ('success')
          },
          cbError: (data, next) => {
            if (data?.result[0]?.code === 'error/syntax/extravalue') {
              this.errorMsg = this.$t ('view.label.locking.error.status')
            } else if (data?.result[0]?.code === 'error/datamgmt/value') {
              this.errorMsg = this.$t ('view.label.locking.error.notSupported')
            } else if (data?.result[0]?.code === 'error/server/processing') {
              if (this.performLock) {
                this.errorMsg = this.$t ('view.label.locking.error.billing')
              } else {
                this.errorMsg = data.errors[0].message
              }
            } else {
              next (data)
            }
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
