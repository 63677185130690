<messages>["../Domain"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="title"
    :is-loading="isLoading"
    :error-msg="errorMsg"
    :custom-btn-text="$t('view.label.changePhase.btn')"
    @ok="submit">
    <launch-phase-select
      v-model="newPhase"
      :is-loading="loadingMeta"
      :label="$t('view.label.changePhase.newPhase')" :available-phases="availableLPs" null-selectable/>
  </confirmation-dialog>
</template>

<script>
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import {mapActions, mapMutations} from 'vuex'

  import LaunchPhaseSelect from '@/app/core/components/Inputs/LaunchPhaseInput'

  export default {
    components: {ConfirmationDialog, LaunchPhaseSelect},

    props: {
      phaseObject: {
        type: Object,
        default: () => ({
          v_id: null,
          name: 'n/a',
          currentPhase: null,
          registryId: null
        })
      },
      value: {
        type: Boolean,
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: '',
        newPhase: null,
        loadingMeta: false,
        response: null,
        availableLPs: []
      }
    },

    computed: {
      title () {
        return this.$t ('view.label.changePhase.title', this.phaseObject)
      },
      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      }
    },

    watch: {
      showDialog (newVal) {
        if (!newVal) {
          this.isLoading = false
          this.errorMsg = ''
          this.newPhase = null
          this.loadingMeta = false
          this.response = null
        }
      },

      'phaseObject.launchPhase': {
        handler (newVal) {
          this.newPhase = newVal
        },
        immediate: true
      },

      'phaseObject.registryId': {
        handler (newVal) {
          if (newVal) this.loadRegistryMetaData (newVal)
        },
        immediate: true
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      submit () {
        this.isLoading = true
        let name
        let subName

        if (this.newPhase) {
          name = this.newPhase.name
          subName = this.newPhase.subname
        }

        this.fetchData ({
          op: 'domain/change-phase',
          params: {
            id: this.phaseObject.id,
            name,
            subName
          },
          cb: data => {
            this.displaySuccessMessage (
              this.$t (
                !name ? 'view.label.changePhase.success.none' : 'view.label.changePhase.success.phase',
                {name: this.phaseObject.name, phase: name + (subName ? '/' + subName : '')}))

            this.$emit ('changed')
            this.showDialog = false
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      loadRegistryMetaData (regId) {
        this.loadingMeta = true
        this.fetchData ({
          op: 'registry/loadMetaData',
          params: {
            registryId: regId
          },
          cb: data => {
            this.availableLPs = data.registryMetaData?.launchPhases
          },
          cbFinal: () => {
            this.loadingMeta = false
          }
        })
      }
    }
  }

</script>
