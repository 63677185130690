<messages>["./RenewDialog"]</messages>

<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t (`title`)"
    :error-msg="errorMsg"
    :is-loading="isLoading"
    :cancel-action="close"
    @ok="request">
    <v-row>
      <v-col cols="12">
        <p v-text="$t ('intro', {domain: renewObject.name})"/>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-slider
            v-model="renewPeriod"
            class="slider-label"
            thumb-label="always"
            thumb-size="20"
            :label="$t ('label.period')"
            :min="1"
            :max="10"/>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model.trim="price"
              :label="$t('label.price')"
              :error-messages="validationErrors(
                'price',
                {
                  decimal: 'label.invalid.price'
                })"
              @blur="$v.price.$touch()">
              <template #append>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      color="primary"
                      dark
                      v-on="on">
                      help
                    </v-icon>
                  </template>
                  <span v-t="'label.priceTooltip'"/>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col
            cols="6">
            <currency-select :v="$v.currency"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="supportsPromotionCode" cols="12">
        <v-text-field
          v-model="promoCode"
          :label="$t ('label.promotionCode')"/>
      </v-col>
      <v-col v-if="response" cols="12">
        <v-btn text color="error" @click="showDetails = !showDetails">
          {{ $t(`general.label.${showDetails ? 'hideErrorDetails' : 'showErrorDetails'}`) }}
        </v-btn>
      </v-col>
      <v-col v-if="response && showDetails" cols="12">
        <order-processing-response :response="response" :show-card-border="false" hide-title/>
      </v-col>
    </v-row>
  </confirmation-dialog>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import {requiredIf} from 'vuelidate/lib/validators'

  import {decimal} from '@/app/validators/index'

  import CurrencySelect from '@/app/core/components/CurrencySelect'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import OrderProcessingResponse
    from '@/app/core/components/RegistryObject/OrderProcessingResponse'

  export default {
    name: 'RenewDialog',

    components: {
      ConfirmationDialog,
      CurrencySelect,
      OrderProcessingResponse
    },

    validations: {
      currency: {
        required: requiredIf (function () {
          return this.price
        })
      },
      price: {
        decimal
      }
    },

    mixins: [validationMixins],

    props: {
      renewObject: {
        type: Object,
        default: () => ({
          v_id: null,
          name: 'n/a',
          registryId: null
        })
      },
      value: {
        type: Boolean,
        required: true
      }
    },

    data () {
      return {
        isLoading: false,
        errorMsg: '',
        renewPeriod: 1,
        price: '',
        currency: '',
        supportsPromotionCode: false,
        promoCode: '',
        loadingMeta: false,
        response: null,
        showDetails: false
      }
    },

    computed: {
      showDialog: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      }
    },

    watch: {
      showDialog (newVal) {
        if (!newVal) {
          this.errorMsg = ''
          this.renewPeriod = 1
          this.price = ''
          this.currency = ''
          this.promoCode = ''
          this.response = null
          this.showDetails = false
        }
      },

      'renewObject.registryId': {
        handler (newVal) {
          if (newVal) this.loadRegistryMetaData (newVal)
        },
        immediate: true
      }

    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      close () {
        this.showDialog = false
      },

      loadRegistryMetaData (regId) {
        this.loadingMeta = true
        this.fetchData ({
          op: 'registry/loadMetaData',
          params: {
            registryId: regId
          },
          cb: data => {
            if (data.metaDataSupported) {
              const rmd = data.registryMetaData
              this.supportsPromotionCode = rmd.supportsPromotionCode
            } else {
              this.supportsPromotionCode = false
            }
          },
          cbFinal: () => {
            this.loadingMeta = false
          }
        })
      },

      request () {
        if (this.$v.$invalid) {
          this.$v.$touch ()
        } else {
          this.errorMsg = ''
          this.response = null
          this.isLoading = true

          this.fetchData ({
            op: 'domain/renew',
            params: {
              id: this.renewObject.v_id,
              years: this.renewPeriod,
              priceLimit: {
                value: this.price !== '' ? this.price : null,
                currency: this.price !== '' ? this.currency : null
              },
              promotionCode: this.supportsPromotionCode && this.promoCode !== '' ? this.promoCode : null
            },
            cb: data => {
              this.isLoading = false
              this.displaySuccessMessage (
                this.$t (
                  'success',
                  {expirationDate: this.formatDate (data.expirationDate)}))
              this.addEvent ({
                type: 'success',
                message: this.$t (
                  'success',
                  {expirationDate: this.formatDate (data.expirationDate)}),
                objects: [{name: this.renewObject.name, link: {name: 'domain.view', params: {name: this.renewObject.name}}}]
              })
              this.$emit ('renewed')
              this.close ()
            },
            cbError: data => {
              if (data.errors ? data.errors.length > 0 : false) {
                this.errorMsg = data.errors[0].message
                this.response = data.errors
              } else {
                this.errorMsg = this.$t ('error')
              }
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        }
      }
    }
  }
</script>
